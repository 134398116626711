export default {
  namespaced: true,
  state: {
    team: {
      meta: {
        pagination: {
          page: 1,
          total_objects: 8,
        },
      },
      data: [
        {
          id: '8u7y6t5r4e',
          name: 'Victor G',
          email: 'contacto@monoestereo.com.mx',
          git: '',
          logo: '',
          positions: [
            {
              id: '345t34566',
              name: 'Analisis',
              color: 'light-success',
            },
            {
              id: '234r6y34',
              name: 'Design',
              color: 'light-warning',
            },
          ],
          created_at: '',
        },
        {
          id: 'q2w3e4r5t6',
          name: 'Gerardo R',
          email: 'gerry.rendon@gmail.com',
          git: 'klosterlight',
          logo: '',
          positions: [
            {
              id: '8fue783y',
              name: 'Backend',
              color: 'light-info',
            },
          ],
          created_at: '2019-09-11',
        },
        {
          id: '0o9i8u7y6t',
          name: 'Luis G',
          email: 'lsgrd@gmail.com',
          git: 'lsgrrd',
          logo: '',
          positions: [
            {
              id: '245fr23rf',
              name: 'Frontend',
              color: 'light-primary',
            },
          ],
          created_at: '',
        },
        {
          id: '4r7y8i9u7y6t',
          name: 'Roxana O',
          email: 'roxanaorozcozalaya@gmail.com',
          git: '',
          logo: '',
          positions: [
            {
              id: '234r6y34',
              name: 'Design',
              color: 'light-warning',
            },
            {
              id: 'fg3456g',
              name: 'PM',
              color: 'light-danger',
            },
          ],
          created_at: '2019-02-01',
        },
        {
          id: '0oe03iu9ur84y7t',
          name: 'Manuel S',
          email: '',
          git: '',
          logo: '',
          positions: [
            {
              id: '245fr23rf',
              name: 'Frontend',
              color: 'light-primary',
            },
          ],
          created_at: '',
        },
        {
          id: '8u7y2w3e2w1q',
          name: 'Angel C',
          email: '',
          git: '',
          logo: '',
          positions: [
            {
              id: '8fue783y',
              name: 'Backend',
              color: 'light-info',
            },
          ],
          created_at: '',
        },
        {
          id: '9it58ut7y389ue',
          name: 'Ricardo G',
          email: '',
          git: '',
          logo: '',
          positions: [
            {
              id: '8fue783y',
              name: 'Backend',
              color: 'light-info',
            },
          ],
          created_at: '',
        },
        {
          id: '0oe03iu9ur84y7t',
          name: 'Carlos C',
          email: '',
          git: '',
          logo: '',
          positions: [
            {
              id: '245fr23rf',
              name: 'Frontend',
              color: 'light-primary',
            },
          ],
          created_at: '',
        },
      ],
    },
  },
  getters: {
    team(state) {
      return state.team.data
    },
    teamPagination(state) {
      return state.team.meta.pagination
    },
  },
  mutations: {
    SET_TEAM(state, payload) {
      state.team = payload
    },
  },
  actions: {

  },
}
